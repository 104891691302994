import React from 'react';
import { Helmet } from 'react-helmet';

import styles from './fonts.css';
const importThis = () => styles;
importThis();

// https://google-webfonts-helper.herokuapp.com/fonts

const LoadFonts = () => (
  <>
    <Helmet>
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        href="https://cdn.figure.com/fonts/gothic-a1-v8-latin-regular.woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        href="https://cdn.figure.com/fonts/gothic-a1-v8-latin-500.woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        href="https://cdn.figure.com/fonts/gothic-a1-v8-latin-700.woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        href="https://cdn.figure.com/fonts/poppins-v15-latin-700.woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  </>
);

export default LoadFonts;
