import React from 'react';
import Svg from '../Gui/Svg/Svg';

export const ArticleCardArrow = (
  <Svg width="31" height="12" viewBox="0 0 31 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.8931 6.74728C30.1796 6.46077 30.1796 5.99626 29.8931 5.70975L25.2242 1.04088C24.9377 0.754376 24.4732 0.754376 24.1867 1.04088C23.9002 1.32739 23.9002 1.7919 24.1867 2.07841L28.3368 6.22852L24.1867 10.3786C23.9002 10.6651 23.9002 11.1296 24.1867 11.4162C24.4732 11.7027 24.9377 11.7027 25.2242 11.4162L29.8931 6.74728ZM0.028656 6.96216H29.3743V5.49487H0.028656V6.96216Z"
      fill="#42368E"
    />
  </Svg>
);
