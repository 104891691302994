import React from 'react';
import Svg from '../Gui/Svg/Svg';

export const LinkedInIcon = (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    isInformative={true}
    title={'LinkedIn'}
  >
    <path
      d="M19.7703 0.951416H2.17392C1.33101 0.951416 0.649414 1.61688 0.649414 2.43963V20.1086C0.649414 20.9313 1.33101 21.6008 2.17392 21.6008H19.7703C20.6132 21.6008 21.2988 20.9313 21.2988 20.1126V2.43963C21.2988 1.61688 20.6132 0.951416 19.7703 0.951416ZM6.77568 18.5478H3.71053V8.69092H6.77568V18.5478ZM5.2431 7.3479C4.25903 7.3479 3.46451 6.55338 3.46451 5.57334C3.46451 4.5933 4.25903 3.79878 5.2431 3.79878C6.22315 3.79878 7.01766 4.5933 7.01766 5.57334C7.01766 6.54935 6.22315 7.3479 5.2431 7.3479ZM18.2458 18.5478H15.1847V13.7565C15.1847 12.6151 15.1645 11.143 13.5916 11.143C11.9985 11.143 11.7565 12.3893 11.7565 13.6758V18.5478H8.69946V8.69092H11.6356V10.038H11.6759C12.0832 9.26362 13.0834 8.4449 14.5716 8.4449C17.6731 8.4449 18.2458 10.4856 18.2458 13.1394V18.5478Z"
      fill="#1A1B22"
    />
  </Svg>
);

export const TwitterIcon = (
  <Svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    isInformative={true}
    title={'Twitter'}
  >
    <path
      d="M6.4961 17.665C14.2864 17.665 18.5486 11.2092 18.5486 5.61251C18.5486 5.43102 18.5446 5.24549 18.5365 5.064C19.3656 4.4644 20.0811 3.7217 20.6494 2.87081C19.8772 3.21437 19.0574 3.43874 18.2179 3.53627C19.1018 3.00642 19.7637 2.17404 20.0808 1.19345C19.2492 1.68627 18.3398 2.03392 17.3915 2.22148C16.7526 1.54259 15.9078 1.09308 14.9878 0.942458C14.0677 0.791833 13.1237 0.94848 12.3017 1.38818C11.4796 1.82788 10.8253 2.52614 10.4399 3.37501C10.0545 4.22388 9.95941 5.17607 10.1694 6.08438C8.48562 5.99988 6.83835 5.56247 5.33442 4.8005C3.83049 4.03853 2.50348 2.96901 1.43941 1.66129C0.898594 2.59372 0.733103 3.69709 0.976572 4.74715C1.22004 5.79721 1.8542 6.71517 2.75016 7.31447C2.07753 7.29311 1.41963 7.11201 0.830817 6.78613V6.83856C0.830214 7.81708 1.1685 8.76561 1.78816 9.52291C2.40783 10.2802 3.27063 10.7996 4.22991 10.9926C3.60681 11.1631 2.95285 11.188 2.31862 11.0652C2.58931 11.9068 3.11597 12.6428 3.82511 13.1706C4.53424 13.6984 5.39046 13.9916 6.27428 14.0094C4.77382 15.188 2.92032 15.8273 1.01231 15.8243C0.673934 15.8238 0.335896 15.803 0 15.7622C1.93834 17.0057 4.19315 17.6662 6.4961 17.665Z"
      fill="#1A1B22"
    />
  </Svg>
);
