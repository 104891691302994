import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({ title, description }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(graphql`
    query seo {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
#          siteUrl: url
        }
      }
    }
  `);

  const { defaultTitle, defaultDescription, siteUrl } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      {seo.url ? <meta property="og:url" content={seo.url} /> : null}
    </Helmet>
  );
};

export default Seo;
