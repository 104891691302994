import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import LoadFonts from '../../fonts/Website';
import {
  Color,
  Width,
  maxWidthContainer,
  Fonts,
  globalH1Default,
  globalH2Default,
  globalH3Default,
  globalH4Default,
  globalH5Default,
  globalH6Default,
} from '../../styles';
import Seo from '../SEO';

const Wrapper = styled.main`
  ${({ maxWidth }) => maxWidthContainer(maxWidth)};
`;

const GlobalStyle = createGlobalStyle`
  html{
    background: ${Color.WHITE};
  }
  body {
    background: ${({ bodyBackground }) => bodyBackground};
    scrollbar-width: none; 
    -ms-overflow-style: none; 
    ::-webkit-scrollbar {
        display: none;
    }
  }
  
  h1, h2, h3, h4, h5, h6 {
    ${Fonts.POPPINS};
  }
  
  h1 {
    ${globalH1Default};
  }
  
  h2 {
    ${globalH2Default};
  }
  
  h3 {
    ${globalH3Default};
  }
  
  h4 {
    ${globalH4Default};
  }
  
  h5 {
    ${globalH5Default};
  }
  
  h6 {
    ${globalH6Default};
  }
  
  p, a {
    ${Fonts.GOTHIC};
    font-size: 1.5rem;
    line-height: calc(27/15);
    letter-spacing: calc(0.26em/15);
  }
  
  a {
    text-decoration: underline;
  }
`;

const BasePage = ({ children, bodyBackground, maxWidth = Width.XL }) => (
    <>
      <LoadFonts />
      <Seo />
      <GlobalStyle bodyBackground={bodyBackground} />
      <Wrapper maxWidth={maxWidth}>{children}</Wrapper>
    </>
  );

export default BasePage;
